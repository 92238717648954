import {
    all,
    put,
    takeLeading,
} from 'redux-saga/effects';
import { simpleApiHandler } from '@frontend/jetlend-core/src/ducks/api';
import {
    modalFormHandler,
    simpleFormHandler,
} from '@frontend/jetlend-core/src/ducks/form';
import { LOGOUT } from '@frontend/jetlend-core/src/ducks/router';
import { safeSaga } from '@frontend/jetlend-core/src/ducks/utils';
import {
    IAccountUsersApiModel,
    IAccountInfoApiModel,
} from '@app/models/common/account';
import {
    apiGetAccountInfo,
    apiGetAccountUsersData,
    apiPostAccountLogout,
    apiPostAccountSwitch,
} from '@app/services/client/common/accountService';
import { refreshPage } from '../router';

export const VERSION = 2;
export const PREFIX = 'common_account';

export const userHandler = simpleApiHandler<IAccountUsersApiModel>(PREFIX, 'users', apiGetAccountUsersData);
export const fetchUser = userHandler.fetch;
export const getUsers = userHandler.selector;
export const accountInfoHandler = simpleApiHandler<IAccountInfoApiModel>(PREFIX, 'info', apiGetAccountInfo);

export const userModalHandler = modalFormHandler(
    PREFIX, 'users_modal', {}, {
        apiMethod: apiPostAccountSwitch,
        onSuccessUpdate: [
            accountInfoHandler,
        ],
    }
);

export const logoutAccountFormHandler = simpleFormHandler(
    PREFIX, 'logout', {}, {
        apiMethod: apiPostAccountLogout,
        *onSuccess() {
            yield put(refreshPage);
        },
    }, {}
);

function* handleLogoutSaga() {
    yield put(logoutAccountFormHandler.submit({}));
}

export function* subscriptions() {
    yield all([
        takeLeading(LOGOUT, safeSaga(handleLogoutSaga, PREFIX)),
    ]);
}