// TODO Remove all HTML formatters, extract if it required to web-ui part

import React from 'react';

import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import 'moment/locale/ru';
import { numWordForm } from '@core/utils/langUtils';
import {
    COMPANY_NAME_PREFIXES,
    INDIVIDUAL_NAME_PREFIX,
} from './isCompanyName';

export const CURRENCY_RUB = '₽';

function isEmptyFloat(value: any): boolean {
    return value === null || value === undefined || Number.isNaN(value);
}

/**
 * TODO
 * @category Formatters
 */
export function splitNumberWithDigestGroups(value: any, fractionDigits = 1, defaultValue: any = '-', floor = false) {
    if (isEmptyFloat(value)) {
        return defaultValue;
    }

    const floatValue = Number.parseFloat(value);
    const isNegative = floatValue < 0.0;
    const parseNumber = Math.abs(Number.parseFloat(value));

    const dotIndex = parseNumber
        .toString()
        .indexOf('.');

    let string = '';

    if (floor) {
        string = dotIndex === -1
            ? parseNumber.toString()
            : parseNumber.toString().slice(0, dotIndex + (fractionDigits === 0 ? 0 : 1 ) + fractionDigits);
    } else {
        string = parseNumber
            .toFixed(fractionDigits)
            .toString();
    }

    let remainingString = '';
    const commaIndex = string.indexOf('.');
    if (commaIndex > 0) {
        remainingString = string.substring(commaIndex).replace('.', ',');
        string = string.substring(0, commaIndex);
    }

    const parts = [];
    while (string.length > 3) {
        parts.push(string.substring(string.length - 3));
        string = string.substring(0, string.length - 3);
    }

    while (string.length > 3) {
        parts.push(string.substring(string.length - 3));
        string = string.substring(0, string.length - 3);
    }

    if (string.length > 0) {
        parts.push(string);
    }

    return (isNegative ? '-' : '') + (parts.reverse().join(' ') + remainingString).trim();
}

/**
 * Заменяет все вхождения обычного пробельного символа на неразрывный пробел в указанной строке.
 *
 * @param {string} value - Строка, в которой должны быть заменены пробелы на неразрывные пробелы.
 * @returns {string} - Результирующая строка с неразрывными пробелами.
 *
 * @category Formatters
 */
export const formatStringWithNonBreakingSpace = (value: string): string => value.replaceAll(' ', '\u00A0');

/**
 * TODO
 * @category Formatters
 */
export function formatAmountWithFractions(
    value: any,
    fractionDigits: number,
    currency: string = CURRENCY_RUB,
    asHtml = true,
    defaultValue: string|JSX.Element = '',
    floor = false
): string|JSX.Element {
    if (isEmptyFloat(value)) {
        return defaultValue;
    }

    const result = splitNumberWithDigestGroups(value, fractionDigits, defaultValue, floor) + (currency ? ` ${currency}` : '');
    if (asHtml) {
        return <span className="h-ws-nowrap">{result}</span>;
    }

    return result;
}

const THOUSANDS = 1000;
const MILLIONS = THOUSANDS * 1000;
const BILLIONS = MILLIONS * 1000;

/**
 * Форматирует значение суммы к виду, указанному в параметрах
 *
 * @param {any} value - форматируемое значение
 * @param {number} fractionDigits - число знаков после запятой
 * @param {string} currency - постфикс суммы
 * @param {boolean} asHtml - флаг регулирующий тип возвращаемого значения
 * @param {string|JSX.Element} defaultValue - значение по-умолчанию
 * @param {boolean} formatAllThousands - флаг регулирующий форматирование значений от 1000 до 100000
 *
 * @category Formatters
 */
export function formatAmountWithPostfix(
    value: any,
    fractionDigits = 1,
    currency: string = CURRENCY_RUB,
    asHtml = true,
    defaultValue: string|JSX.Element = '',
    formatAllThousands = false
): string|JSX.Element {
    if (isEmptyFloat(value)) {
        return defaultValue;
    }

    if (value < 100 * THOUSANDS) {
        if (formatAllThousands && value >= 1 * THOUSANDS) {
            const result = `${splitNumberWithDigestGroups(value / THOUSANDS, fractionDigits)} тыс${currency ? ` ${currency}` : ''}`;
            if (asHtml) {
                return <span className="h-ws-nowrap">{result}</span>;
            }
            return result;
        }
        return formatAmountShort(value, currency, asHtml, defaultValue);

    }

    if (value < 0.9 * MILLIONS) {
        const result = `${splitNumberWithDigestGroups(value / THOUSANDS, fractionDigits)} тыс${currency ? ` ${currency}` : ''}`;
        if (asHtml) {
            return <span className="h-ws-nowrap">{result}</span>;
        }

        return result;
    }

    if (value < 10 * BILLIONS) {
        const result = `${splitNumberWithDigestGroups(value / MILLIONS, fractionDigits)} млн${currency ? ` ${currency}` : ''}`;
        if (asHtml) {
            return <span className="h-ws-nowrap">{result}</span>;
        }

        return result;
    }

    const result = `${splitNumberWithDigestGroups(value / BILLIONS, fractionDigits)} млрд${currency ? ` ${currency}` : ''}`;
    if (asHtml) {
        return <span className="h-ws-nowrap">{result}</span>;
    }

    return result;
}

/**
 * Форматирует значение суммы в формат округления до миллионов.
 *
 * @param amount Форматируемое значение суммы.
 * @returns Отформатированное значение в виде строки
 *
 * @category Formatters
 *
 * @example
 * formatMillionsAmount(1200000) // '1,2М ₽'
 */
export function formatMillionsAmount(amount: number): string {
    // Если округление происходит до 0 значения после знака, то отбрасываем эту часть
    return `${splitNumberWithDigestGroups(amount / 1000000).replace(',0', '')}М ${CURRENCY_RUB}`;
}

/**
 * Форматирует значение суммы к виду, указанному в параметрах
 *
 * @param {any} value - форматируемое значение
 * @param {number} fractionDigits - число знаков после запятой
 * @param {string|JSX.Element} defaultValue - значение по-умолчанию
 * @param {boolean} formatAllThousands - флаг регулирующий форматирование значений от 1000 до 100000
 *
 * @returns отформатированное значение в виде строки
 *
 * TODO
 * @category Formatters
 */
export function formatAmountWithPostfixText(value, fractionDigits = 1, defaultValue = '-', formatAllThousands = false): string {
    return formatAmountWithPostfix(value, fractionDigits, undefined, false, defaultValue, formatAllThousands) as string;
}

/**
 * TODO
 * @category Formatters
 */
export function formatAmountShort(value, currency = CURRENCY_RUB, asHtml = true, defaultValue: string|JSX.Element = '-'): string|JSX.Element {
    return formatAmountWithFractions(value, 0, currency, asHtml, defaultValue);
}

/**
 * TODO
 * @category Formatters
 */
export function formatAmount(value, currency = CURRENCY_RUB, asHtml = true, defaultValue: string|JSX.Element = '-', floor = false): string|JSX.Element {
    return formatAmountWithFractions(value, 2, currency, asHtml, defaultValue, floor);
}

/**
 * TODO
 * @category Formatters
 */
export function formatAmountText(value, currency = CURRENCY_RUB, defaultValue: string|JSX.Element = '-', floor = false): string {
    return formatAmount(value, currency, false, defaultValue, floor) as string;
}

/**
 * TODO
 * @category Formatters
 */
export function formatAmountShortText(value, currency = CURRENCY_RUB, defaultValue = '-'): string {
    return formatAmountShort(value, currency, false, defaultValue) as string;
}

/**
 * TODO
 * @category Formatters
 */
export function formatFloatNumber(value: any, defaultValue = '-'): string {
    if (isEmptyFloat(value)) {
        return defaultValue;
    }

    return `${Math.round(Number.parseFloat(value) * 100) / 100}`.replace('.', ',');
}

/**
 * TODO
 * @category Formatters
 */
export function formatPercentNumber(percentValue: any, defaultValue = '-'): string {
    if (isEmptyFloat(percentValue)) {
        return defaultValue;
    }

    return `${Math.round(Number.parseFloat(percentValue) * 10000) / 100}`.replace('.', ',');
}

/**
 * TODO
 * @category Formatters
 */
export function formatToMillions(value: number, fractionDigits = 1, currency: string = CURRENCY_RUB, defaultValue = '-', formatBillions = true, formatLessThanMillion = true) {
    if (isEmptyFloat(value)) {
        return defaultValue;
    }

    // IF formatLessThanMillion = false, should not format values, that are less than million
    if (!formatLessThanMillion && Math.abs(value) < MILLIONS) {
        return formatAmountShortText(value, currency);
    }

    if (Math.abs(value) < BILLIONS*9.99995 || !formatBillions) {
        return `${splitNumberWithDigestGroups(value / MILLIONS, fractionDigits)} млн ${currency}`;
    }

    return `${splitNumberWithDigestGroups(value / BILLIONS, fractionDigits)} млрд ${currency}`;
}

interface FormatPercentOptions {
    defaultValue?: string;
    addSpace?: boolean;
    fractionDigits?: number;
    multiply?: number;
}

/**
 * TODO
 * @category Formatters
 */
export function formatPercentExt(percentValue: any, {
    defaultValue = '-',
    addSpace = true,
    fractionDigits = 0,
    multiply = 100,
}: FormatPercentOptions = {}) {
    if (isEmptyFloat(percentValue)) {
        return defaultValue;
    }

    const suffix = addSpace ? ' %' : '%';
    return `${(Number.parseFloat(percentValue) * multiply).toFixed(fractionDigits)}${suffix}`.replace('.', ',');
}

/**
 * TODO
 * @category Formatters
 */
export function formatPercent(percentValue: any, defaultValue = '-', addSpace = true): string {
    if (isEmptyFloat(percentValue)) {
        return defaultValue;
    }

    const suffix = addSpace ? ' %' : '%';
    return formatPercentNumber(percentValue, defaultValue).replace('.', ',') + suffix;
}

/**
 * TODO
 * @category Formatters
 */
export function formatPercentLong(percentValue: any, defaultValue = '-'): string {
    if (isEmptyFloat(percentValue)) {
        return defaultValue;
    }

    return `${(Number.parseFloat(percentValue) * 100.0).toFixed(1).replace('.', ',')} %`;
}

/**
 * TODO
 * @category Formatters
 */
export function formatPercentCss(percentValue: any, defaultValue = '-', ceil = false): string {
    if (isEmptyFloat(percentValue)) {
        return defaultValue;
    }

    if (ceil) {
        return `${Math.ceil(Number.parseFloat(percentValue) * 100)}%`;
    }

    return `${Math.round(Number.parseFloat(percentValue) * 100)}%`;
}

/**
 * TODO
 * @category Formatters
 */
export function parseDate(value: any, exposeNullOnError = false) {
    if (!value && exposeNullOnError) {
        return null;
    }

    if (typeof value === 'number' && value.toString().length === 10) {
        return Moment.unix(value);
    }

    let m = Moment(value);
    if (!m.isValid()) {
        m = Moment(value, 'DD-MM-YYYY');
    }

    if (!m.isValid() && exposeNullOnError) {
        return null;
    }

    return m.locale('ru');
}

/**
 * TODO
 * @category Formatters
 */
export function quarterText(q: number): string {
    switch (q) {
    case 1:
        return 'I Кв.';
    case 2:
        return 'II Кв.';
    case 3:
        return 'III Кв.';
    case 4:
        return 'IV Кв.';
    default:
        return null;
    }
}

/**
 * TODO
 * @category Formatters
 */
export function formatDate(value: any, defaultValue = '-'): string {
    if (!value) {
        return defaultValue;
    }

    const m = parseDate(value);
    const localeData = m.localeData();

    return m.format(localeData.longDateFormat('L'));
}

/**
 * Format a date as 'YYYY-MM-DD'
 */
export function toAPIDate(value: any, exposeNullOnError = false): string | undefined {
    const date = parseDate(value, exposeNullOnError);

    return date?.format('YYYY-MM-DD');
}

/**
 * TODO
 * @category Formatters
 */
export function formatMinutesDuration(minutes: any, defaultValue = '-'): string {
    const v = Number.parseInt(minutes);

    if (!v || Number.isNaN(v)) {
        return defaultValue;
    }

    if (v <= 0) {
        return '-';
    }

    if (v <= 60) {
        return '< 1 часа';
    }

    const hours = Math.floor(v / 60.0);
    const days = Math.floor(hours / 24.0);

    if (days >= 1) {
        return numWordForm(days, 'day');
    }

    return numWordForm(hours, 'hours');
}

/**
 * TODO
 * @category Formatters
 */
export function parseFormattedDate(value: any, exposeNullOnError = false) {
    if (!value && exposeNullOnError) {
        return null;
    }

    const localeData = Moment.localeData();
    return Moment(value, localeData.longDateFormat('L'));
}

/**
 * TODO
 * @category Formatters
 */
export function formatDateTime(value: any): string {
    if (!value) {
        return '-';
    }

    const m = parseDate(value);
    const localeData = m.localeData();

    return m.format(`${localeData.longDateFormat('L')} HH:mm`);
}

/**
 * TODO
 * @category Formatters
 */
export function formatDateTimeTimezone(value: any): string {
    if (!value) {
        return '-';
    }
    function convertTimeZoneOffset(timeZoneOffset) {
        const sign = timeZoneOffset[0];
        const parts = timeZoneOffset.split(':');
        const hours = parseInt(parts[0]);
        const minutes = parseInt(parts[1]);
        const offset = hours + minutes / 60;
        return sign + offset;
    }
    const date = MomentTimezone(parseDate(value));
    const timezoneOffset: string = convertTimeZoneOffset(date.tz('Europe/Moscow').format('Z'));
    return `${date.format('DD.MM.YYYY HH:mm:ss')} (GMT${timezoneOffset})`;
}

/**
 * TODO
 * @category Formatters
 */
export function buildTelegramLink(username: string): string {
    return `https://telegram.me/${encodeURIComponent(username)}`;
}

/**
 * TODO
 * @category Formatters
 */
export function buildWhatsAppLink(phone: string): string {
    const clearPhone = phone.replace(/[+\-_()\s]/ig, '');

    return `https://api.whatsapp.com/send?phone=${clearPhone}`;
}

/**
 * TODO
 * @category Formatters
 */
export function buildPhoneLink(phone: string): string {
    const clearPhone = phone.replace(/[-_()\s]/ig, '');

    return `tel:${clearPhone}`;
}

/**
 * TODO
 * @category Formatters
 */
export function buildEmailLink(email: string): string {
    return `mailto:${email}`;
}

/**
 * TODO
 * @category Formatters
 */
export function renderSeconds(seconds) {
    if (seconds <= 0) {
        return '00:00';
    }

    const naturalSeconds = Math.floor(seconds);

    return `${(Math.floor(naturalSeconds / 60.0)).toFixed(0)
        .padStart(2, '0')}:${(naturalSeconds % 60.0).toFixed(0).padStart(2, '0')}`;
}

/**
 * TODO
 * @category Formatters
 */
export function renderHourSeconds(seconds) {
    if (seconds <= 0) {
        return '00:00:00';
    }

    const naturalSeconds = Math.floor(seconds);

    return `${(Math.floor(naturalSeconds / 3600.0)).toFixed(0)
        .padStart(2, '0')}:${(Math.floor(naturalSeconds % 3600 / 60.0)).toFixed(0)
        .padStart(2, '0')}:${(naturalSeconds % 60.0).toFixed(0).padStart(2, '0')}`;
}

/**
 * TODO
 * @category Formatters
 */
export function formatTimeDuration(seconds: number, labels?: string[]): string {
    const secondLabel = labels && labels.length > 2 ? labels[2] : 'с';
    const minuteLabel = labels && labels.length > 1 ? labels[1] : 'м';
    const hourLabel = labels && labels.length > 0 ? labels[0] : 'ч';

    if (typeof seconds !== 'number' || Number.isNaN(seconds)) {
        return '-';
    }

    if (seconds < 60) {
        return `${seconds.toFixed(0)}${secondLabel}`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
        const leftSeconds = seconds % 60;

        if (leftSeconds === 0) {
            return `${minutes.toFixed(0)}${minuteLabel}`;
        }

        return `${minutes.toFixed(0)}${minuteLabel} ${leftSeconds.toFixed(0)}${secondLabel}`;
    }

    const hours = Math.floor(minutes / 60);
    const leftMinutes = minutes % 60;
    const leftSeconds = seconds % 60;

    let result = `${hours.toFixed(0)}${hourLabel}`;
    if (leftMinutes > 0) {
        result += ` ${leftMinutes.toFixed(0)}${minuteLabel}`;
    }
    if (leftSeconds > 0) {
        result += ` ${leftSeconds.toFixed(0)}${secondLabel}`;
    }

    return result;
}

/**
 * Преобразовывает текстовую строку с номером телефона в стандартный формат, со скобками и разделителями.
 * Поддерживает телефон, на который была наложена маска.
 *
 * Если переданный на вход номер телефона оказался не валидным, то возвращает пустую строку.
 *
 * @param value входная строка для форматирования
 * @param firstNumber формат кода страны
 * @param separator разделить номерных групп
 *
 * @category Formatters
 *
 * @example
 *  formatPhoneNumber('89811234567')
 *      // -> '+7 (981) 123-45-67'
 *
 *  formatPhoneNumber('8******4567')
 *      // -> '+7 (***) ***-45-67'
 *
 *  formatPhoneNumber('acacacac')
 *      // -> ''
 *
 *  formatPhoneNumber('+7 981 123 45 67', '8', ' ')
 *      // -> '8 (981) 123 45 67'
 */
export function formatPhoneNumber(
    value: string,
    firstNumber: '+7'|'8' = '+7',
    separator = '-'
): string {
    if (!value) {
        return '';
    }

    const cleanedNumber = value.replace(/[^\d*]/g, '');
    if (cleanedNumber === '') {
        return '';
    }

    const formattedNumber = cleanedNumber.replace(/^(?:\+?7|8)/, '');
    if (formattedNumber.length < 10) {
        return value;
    }

    const lastTenDigits = formattedNumber.slice(-10);
    return lastTenDigits.replace(
        /([\d*]{3})([\d*]{3})([\d*]{2})([\d*]{2})/,
        `${firstNumber} ($1) $2${separator}$3${separator}$4`
    );
}

/**
 * Formatting Full name to Initials
 * @param name in formats — 'Хорошев Роман Петрович' | 'Хорошев Роман', may start with 'ИП | "ООО'
 * @return string
 *
 * @example
 * formatNameToInitials('Хорошев Роман Петрович') — 'XP'
 *
 * @category Formatters
 */

export const formatNameToInitials = (name: string) => {
    // Не сокращаем технические имена
    if (/^ID\s\d+$/i.test(name)) {
        return '—';
    }

    const nameData = name.split(' ').map(item => item.replace(/[^a-zA-Zа-яА-Я]/g, '').toUpperCase());
    if (nameData.length < 2) {
        return '—';
    }

    if (nameData[0] === INDIVIDUAL_NAME_PREFIX) {
        if (nameData.length < 3) {
            return '—';
        }

        const lastName = nameData[1][0];
        const firstName = nameData[2][0];

        return `${lastName}${firstName}`;
    }

    if (COMPANY_NAME_PREFIXES.includes(nameData[0])) {
        // Returns only first letter of title
        return nameData[1][0];
    }

    const lastName = nameData[0][0];
    const firstName = nameData[1][0];

    return `${lastName}${firstName}`;
};

/**
 * Функция для отрисовки секунд в скобках, и текст после них
 * Если секунды это не положительное число, функция вернет только текст
 *
 * @param text - текст
 * @param seconds - секунды
 *
 * @example
 * renderTextWithSeconds('text', 10) — '(10) text'
 * renderTextWithSeconds('text', 0) — 'text'
 * renderTextWithSeconds('text', -10) — 'text'
 *
 * @category Formatters
 */
export const renderTextWithSeconds = (text: string, seconds?: number): string => {
    if (seconds > 0) {
        return `(${seconds}) ${text}`;
    }

    return text;
};

/** Округление числа до определённого значения
 * @param num - число, которое нужно округлить
 * @param round - значение, до кратности которого нужно округлить
 *
 *  * @example
 * renderTextWithSeconds(1234567, 1000) — 1235000
 *
 * @category Formatters
*/
export const roundTo = (num: number, round: number) => {
    if (round === 0) {
        return;
    }

    if (num === 0) {
        return 0;
    }

    if (!round || !num) {
        return;
    }

    return Math.round(num / round) * round;
};

/**
 * Функция для преобразования байтов в строку с указанием единицы измерения
 * @param bytes - количество байт
 * @param fractionDigits - количество знаков после запятой
 *
 * @example
 * formatBytes(1024) — '1 KB'
 * formatBytes(1048576, 2) — '1.00 MB'
 *
 * @category Formatters
 */
export const formatBytes = (bytes: number, fractionDigits = 0): string => {
    if (bytes < 1024) {
        return `${bytes} B`;
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;

    while (bytes >= 1024 && i < units.length - 1) {
        bytes /= 1024;
        i += 1;
    }

    return `${bytes.toFixed(fractionDigits)} ${units[i]}`;
};