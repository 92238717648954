import {
    all,
    takeEvery,
} from 'redux-saga/effects';
import * as sharedRouter from '@frontend/jetlend-core/src/ducks/router';
import { safeSaga } from '@frontend/jetlend-core/src/ducks/utils';

export const BASE_URL_PATH = process.env.PUBLIC_URL || '/';

export const VERSION = 2;
export const PREFIX = sharedRouter.PREFIX;

const REFRESH_PAGE = sharedRouter.REFRESH_PAGE;
const REDIRECT_TO_HOME = sharedRouter.REDIRECT_TO_HOME;
const REDIRECT_TO = sharedRouter.REDIRECT_TO;
const REDIRECT_TO_EXTERNAL = sharedRouter.REDIRECT_TO_EXTERNAL;

export const refreshPage = sharedRouter.refreshPage;
export const redirectToLogin = sharedRouter.redirectToLogin;
export const logout = sharedRouter.logout;

function refreshPageSaga() {
    document.location.reload();
}

function redirectToPathSaga({ path }: { path: string }) {
    document.location.href = `${BASE_URL_PATH}${path}`;
}

function redirectToHomeSaga() {
    document.location.href = `${BASE_URL_PATH}`;
}

function redirectToExternalSaga({ path }: { path: string }) {
    document.location.href = path;
}

export function* subscriptions() {
    yield all([
        takeEvery(REFRESH_PAGE, safeSaga(refreshPageSaga, PREFIX)),
        takeEvery<any>(REDIRECT_TO, safeSaga(redirectToPathSaga, PREFIX)),
        takeEvery<any>(REDIRECT_TO_EXTERNAL, safeSaga(redirectToExternalSaga, PREFIX)),
        takeEvery<any>(REDIRECT_TO_HOME, safeSaga(redirectToHomeSaga, PREFIX)),
    ]);
}
