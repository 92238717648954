import {
    ClientType,
    CommonStage,
} from '@app/models/common/common';

export interface ILoginState {
    clientType?: ClientType;
    stage?: CommonStage;
    step?: CommonStage;
    phone?: string;
    password?: string;
    password1?: string;
    password2?: string;
    redirectUri?: string;
    loadingTitle?: string;
    smsId?: number;
}

export interface ILoginFormValues {
    phone: string;
    password: string;
    sms_id?: number;
    /**
     * Тип пользователя.
     */
    clientType: ClientType;
    /**
     * uri для редиректа
     */
    redirect_uri?: string;
    /**
     * Активировать маркет мейкинг автоматически после добавления нового портфеля.
     */
    enable_market_making?: boolean;
}

export interface IPasswordResetFormValues {
    clientType: ClientType;
    password1?: string;
    password2?: string;
    phone?: string;
}

export interface IUserCreateFormValues {
    /**
     * Согласие с правилами платформы.
     */
    confirm: boolean;
    /**
     * Тип пользователя.
     */
    clientType: ClientType;
    /**
     * Дополнительный тип создаваемого пользователя.
     */
    type?: string;
    /**
     * Активировать маркет мейкинг автоматически после добавления нового портфеля.
     * @deprecated остается для обратной совмести со старыми версиями приложения
     */
    enable_market_making: boolean;
    /**
     * Активировать трансфер данных с аккаунта на новый аккаунт
     * @deprecated остается для обратной совмести со старыми версиями приложения
     */
    enable_transfer: boolean;
}

export interface ILoginResultApiModel {
    redirect_uri?: string;
}

/**
 * Тип используемой пользователем платформы.
 * @enum
 */
export enum LoginDeviceType {
    LegacyWeb = 'legacy-web',
    DesktopWeb = 'desktop-web',
    MobileWeb = 'mobile-web',
    Application = 'app',
}
